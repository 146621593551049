export const handleRem = (width) => {
  let rem;
  if (width <= 900) {
    rem = 8;
  }
  if (width > 900 && width <= 1200) {
    rem = 9;
  }
  if (width > 1200 && width <= 1800) {
    rem = 10;
  }
  if (width > 1800) {
    rem = 12;
  }
  return rem;
};
