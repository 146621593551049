import Slider from "react-slick";

const CarouselSmall = ({ images }) => {
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="small">
      <Slider {...settings}>
        {images.map((image) => (
          <img
            key={image.sys.id}
            className="small__img"
            src={image.fields.file.url}
            alt={image.fields.title}
          />
        ))}
      </Slider>
    </div>
  );
};

export default CarouselSmall;
