import Card from "components/Card";
import useGetContentfulEntry from "hooks/useGetContentfulEntry";
import { CONTENTFUL_MENU_SECTION_ID } from "constants/contentful";

const Menu = () => {
  const { entry: menu, loading } =
    useGetContentfulEntry(CONTENTFUL_MENU_SECTION_ID) || {};

  if (loading) {
    return <div>loading...</div>;
  }

  const {
    paneImage,
    paneImageMobile,
    paneDescription,
    dolciImage,
    dolciImageMobile,
    dolciDescription,
    ciboImage,
    ciboImageMobile,
    ciboDescription,
  } = menu;

  return (
    <div className="menu d-flex-col" id="menu">
      <div className="menu__cards d-flex">
        <div className="menu__card d-flex">
          <Card
            title="Pane"
            img={paneImage.fields.file.url}
            mobileImg={paneImageMobile.fields.file.url}
            description={paneDescription}
          />
        </div>
        <div className="menu__card d-flex">
          <Card
            title="Dolci"
            img={dolciImage.fields.file.url}
            mobileImg={dolciImageMobile.fields.file.url}
            description={dolciDescription}
          />
        </div>
        <div className="menu__card d-flex">
          <Card
            title="Cibo"
            img={ciboImage.fields.file.url}
            mobileImg={ciboImageMobile.fields.file.url}
            description={ciboDescription}
          />
        </div>
      </div>
      <a
        href={menu.menu.fields.file.url}
        target="_blank"
        rel="noreferrer"
        className="menu__btn d-flex"
      >
        <span>Ver menú</span>
      </a>
    </div>
  );
};

export default Menu;
