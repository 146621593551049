import { Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import location from "assets/location.svg";

const markerIcon = new Icon({
  iconUrl: location,
  iconRetinaUrl: location,
  popupAnchor: [-0, -0],
  iconSize: [50, 50],
});

const LocationMarker = () => {
  return (
    <>
      <Marker
        position={{ lat: -34.61985039283664, lng: -58.441370044665334 }}
        icon={markerIcon}
      >
        <Popup>You are here.</Popup>
      </Marker>
    </>
  );
};

export default LocationMarker;
