import { useState, useEffect, useCallback } from "react";
import { useWindowSize } from "hooks/useWindowSize";
import { handleRem } from "helpers/handleRem";
import logo from "assets/logo.png";
import flap from "assets/flap.svg";
import instagram from "assets/instagram.svg";
import instagramOrange from "assets/instagram_orange.svg";
import whatsapp from "assets/whatsapp.svg";
import whatsappOrange from "assets/whatsapp_orange.svg";
import useGetContentfulEntry from "hooks/useGetContentfulEntry";

const Header = ({ scroll }) => {
  const [section, setSection] = useState("");

  const [responsive, setResponsive] = useState({
    logoHeight: 0,
    flapHeight: 0,
    viewportHeight: 0,
    linksHeight: 0,
    aboutHeight: 0,
  });

  const { height, width } = useWindowSize();

  const { logoHeight, flapHeight, viewportHeight, aboutHeight, linksHeight } =
    responsive;

  const { entry: header } =
    useGetContentfulEntry("3IDNIjXNCkiCijnbEgz45g") || {};

  const handleResponsive = useCallback(() => {
    let rem = handleRem(width);
    let footer = rem * 5.6;
    let headerPadding = rem * 7;

    if (width < 600) {
      setResponsive({
        logoHeight: height - headerPadding - rem * 6.4,
        flapHeight: height,
      });
    } else {
      let newHeight = 0;

      if (height < 700) {
        newHeight = 700 - footer - 1;
      } else {
        newHeight = height - footer - 1;
      }

      setResponsive({
        // screen height - fix footer height - header padding - logo height
        logoHeight: height - footer - headerPadding - rem * 7,
        // -1 to control a default browser flaw
        flapHeight: height - footer - 1,
        viewportHeight: newHeight,
        aboutHeight: height - footer - 1,
        linksHeight: height - footer - headerPadding,
        newHeight,
      });
    }
  }, [height, width]);

  const handleSection = useCallback(() => {
    if (scroll < viewportHeight) {
      setSection("");
    }
    if (scroll >= aboutHeight) {
      setSection("about");
    }
    if (scroll >= aboutHeight + viewportHeight) {
      setSection("menu");
    }
    if (scroll >= aboutHeight + viewportHeight * 2) {
      setSection("contact");
    }
    if (scroll >= aboutHeight + viewportHeight * 3) {
      setSection("");
    }
  }, [scroll, viewportHeight]);

  useEffect(() => {
    handleResponsive();
  }, [handleResponsive]);

  useEffect(() => {
    handleSection();
  }, [handleSection]);

  return (
    <header className="header d-flex">
      <div className="header__container d-flex">
        <div className="header__links d-flex">
          <a
            href="#about"
            className={`header__link header__link--left ${
              scroll < linksHeight ? "c-white" : "c-black"
            } ${section === "about" ? "underline" : ""}`}
          >
            Nosotros
          </a>
          <a
            href="#menu"
            className={`header__link header__link--left ${
              scroll < linksHeight ? "c-white" : "c-black"
            } ${section === "menu" ? "underline" : ""}`}
          >
            Servicios
          </a>
        </div>
        <div className="header__logo-container">
          <img
            className={`header__logo ${
              scroll < logoHeight ? "fade-in" : "fade-out"
            }`}
            src={logo}
            alt="roma"
          />

          {scroll >= flapHeight && (
            <a href="#home">
              <img className="header__logo--flap" src={flap} alt="roma" />
            </a>
          )}
        </div>
        <div className="header__links header__links--right d-flex">
          <a
            href="#contact"
            className={`header__link header__link--right ${
              scroll < linksHeight ? "c-white" : "c-black"
            } ${section === "contact" ? "underline" : ""}`}
          >
            Contacto
          </a>
          <div className="header__actions d-flex header__link--right">
            {header && (
              <>
                <a
                  href={header.instagram}
                  target="_blank"
                  rel="noreferrer"
                  className="header__action"
                >
                  <img
                    src={instagramOrange}
                    alt="instagram"
                    className={`header__img header__img--orange ${
                      scroll < linksHeight ? "fade-out" : "fade-in"
                    }`}
                  />
                  <img
                    src={instagram}
                    alt="instagram"
                    className={`header__img ${
                      scroll < linksHeight ? "fade-in" : "fade-out"
                    }`}
                  />
                </a>
                <a
                  href={`https://wa.me/${header.whatsapp}?text=`}
                  target="_blank"
                  rel="noreferrer"
                  className="header__action header__action--right"
                >
                  <img
                    src={whatsappOrange}
                    alt="whatsapp"
                    className={`header__img header__img--orange ${
                      scroll < linksHeight ? "fade-out" : "fade-in"
                    }`}
                  />
                  <img
                    src={whatsapp}
                    alt="whatsapp"
                    className={`header__img ${
                      scroll < linksHeight ? "fade-in" : "fade-out"
                    }`}
                  />
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
