import LocationMarker from "./LocationMarker";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const Map = ({ coordinates }) => {
  const mapUrl = `https://{s}.tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token=${process.env.REACT_APP_LEAFLET_MAP_ACCESS_TOKEN}`;

  return (
    <MapContainer className="map__container" center={coordinates} zoom={15}>
      <TileLayer url={mapUrl} />
      <LocationMarker />
    </MapContainer>
  );
};

export default Map;
