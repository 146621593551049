import FixFooter from "components/FixFooter";
import pd from "assets/pd.svg";

const Footer = () => {
  return (
    <footer className="footer d-flex">
      <div className="footer__container d-flex-col">
        <div className="footer__fix--mobile d-flex mobile">
          <img src={pd} alt="pane & dolci" className="footer__img" />
        </div>
        <FixFooter fixed={false} />
        <a
          href="https://www.behance.net/cristabernasconi"
          target="_blank"
          rel="noreferrer"
          className="footer__brand d-flex"
        >
          cristabernasconi &copy; 2022.
        </a>
      </div>
    </footer>
  );
};

export default Footer;
