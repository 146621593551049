import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import famiglia from "assets/famiglia.svg";
import mobile_benvenuti from "assets/mobile_benvenuti.svg";
import Loader from "components/Loader";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [btn, setBtn] = useState("sumate");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const mailFormat = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;

  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      setError("Ingresá tu email");
    } else if (email.match(mailFormat)) {
      setLoading(true);
      emailjs
        .sendForm(
          "roma",
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_EMAILJS_ACCESS_TOKEN
        )
        .then(
          (result) => {
            setBtn("Gracias!");
            setLoading(false);
            setEmail("");
            setTimeout(() => {
              setBtn("Sumate");
            }, 2000);
          },
          (error) => {
            setLoading(false);
            setError("Hubo un error, intente nuevamente más tarde");
          }
        );
    } else {
      setLoading(false);
      setError("Email inválido");
    }
  };

  return (
    <div className="newsletter section d-flex-col">
      <div className="header-placeholder"></div>
      <div className="newsletter__container d-flex-col">
        <div className="newsletter__title d-flex-col desktop">
          <p className="newsletter__benvenuti">Benvenuti alla</p>
          <img src={famiglia} className="newsletter__famiglia" alt="Famiglia" />
        </div>
        <img
          src={mobile_benvenuti}
          alt="benvenuti alla famiglia"
          className="newsletter__title--mobile mobile"
        />
        <p className="newsletter__comment">
          Sé parte de ROMA y <span className="mobile">accedé a </span>
          <span className="desktop">nuestros </span>
          beneficios exclusivos
        </p>
        <form
          ref={form}
          className="newsletter__form d-flex-col"
          onSubmit={handleSubmit}
        >
          <input
            type="text"
            className="newsletter__input"
            placeholder="TU EMAIL"
            value={email}
            onChange={handleChange}
            name="email"
            autoComplete="off"
          />
          {error && <p className="newsletter__error">{error}</p>}
          <button className="newsletter__btn" type="submit">
            {loading ? <Loader /> : <span>{btn}</span>}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Newsletter;
