import { useWindowSize } from "hooks/useWindowSize";
import Carousel from "../components/Carousel/Carousel";
import instagramLogo from "assets/instagram.svg";
import whatsappLogo from "assets/whatsapp.svg";
import useGetContentfulEntry from "hooks/useGetContentfulEntry";
import {
  CONTENTFUL_CAROUSEL_ID,
  CONTENTFUL_HEADER_ID,
} from "constants/contentful";

const Home = () => {
  const { width } = useWindowSize();
  const { entry: carousel, loading } =
    useGetContentfulEntry(CONTENTFUL_CAROUSEL_ID) || {};
  const { entry: header, loading: headerLoading } =
    useGetContentfulEntry(CONTENTFUL_HEADER_ID) || {};

  if (loading || headerLoading) {
    return <div>loading...</div>;
  }

  const { mobileImages, desktopImages } = carousel;
  const { whatsapp, instagram } = header;

  return (
    <div className="section home" id="home">
      {carousel && (
        <Carousel images={width <= 600 ? mobileImages : desktopImages} />
      )}

      <div className="home__links--mobile d-flex-col">
        <a href="#about" className="home__link d-flex">
          <span>Nosotros</span>
        </a>
        <a href="#menu" className="home__link d-flex">
          <span>Servicios</span>
        </a>
        <a href="#contact" className="home__link d-flex">
          <span>Contacto</span>
        </a>
        <div className="home__actions d-flex">
          <a
            href={instagram}
            target="_blank"
            rel="noreferrer"
            className="home__action"
          >
            <img
              src={instagramLogo}
              alt="instagram"
              className="home__instagram"
            />
          </a>
          <a
            href={`https://wa.me/${whatsapp}?text=`}
            target="_blank"
            rel="noreferrer"
            className="home__action"
          >
            <img src={whatsappLogo} alt="whatsapp" className="home__whatsapp" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
