import mobile_fatto from "assets/mobile_fatto.svg";
import amber from "assets/amber.svg";
import amore from "assets/amore.svg";
import useGetContentfulEntry from "hooks/useGetContentfulEntry";
import { CONTENTFUL_ABOUT_SECTION_ID } from "constants/contentful";
const About = () => {
  const { entry: about, loading } =
    useGetContentfulEntry(CONTENTFUL_ABOUT_SECTION_ID) || {};

  if (loading) {
    return <div>loading...</div>;
  }

  const { image, paragraph1, paragraph2, paragraph3 } = about;

  return (
    <div className="section about d-flex-col" id="about">
      <div className="header-placeholder"></div>
      <div className="about__container d-flex-col">
        <div className="about__title d-flex desktop">
          <p className="about__fatto">Fatto con</p>
          <img src={amore} className="about__amore" alt="Fatto con amore" />
        </div>
        <img
          src={mobile_fatto}
          className="about__title--mobile mobile"
          alt="amore"
        />
        <div className="about__content d-flex">
          <div className="about__img-container d-flex desktop">
            <img
              className="about__img "
              src={image.fields.file.url}
              loading="lazy"
              alt={image.fields.title}
            />
          </div>
          <div className="about__description d-flex-col">
            <p className="about__paragraph">{paragraph1}</p>
            <p className="about__paragraph">{paragraph2}</p>
            <p className="about__paragraph">{paragraph3}</p>

            <div className="about__signature d-flex">
              <span>PANE</span> <img src={amber} alt="&" /> <span>DOLCI.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
