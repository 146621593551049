import { useState, useEffect } from "react";
import { getEntry } from "services/contentful";

const useGetContentfulEntry = (id) => {
  const [entry, setEntry] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleEntry = async () => {
    try {
      setLoading(true);
      const entryData = await getEntry(id);
      setEntry(entryData);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleEntry();
  }, [id]);

  return { entry, loading, error };
};

export default useGetContentfulEntry;
