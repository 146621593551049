import Slider from "react-slick";

const Carousel = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: () => <button className="carousel__dot" />,
  };

  return (
    <div className="carousel">
      <Slider {...settings}>
        {images.map((image) => (
          <img
            key={image.sys.id}
            className="carousel__img"
            src={image.fields.file.url}
            alt={image.fields.title}
          />
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
