import { useState } from "react";
const Card = ({ title, img, mobileImg, description }) => {
  const [descriptionClass, setDescriptionClass] = useState("hidden--mobile");

  const handleClass = () => {
    setDescriptionClass("fade-in-mobile");
    setTimeout(() => {
      setDescriptionClass("fade-out-mobile");
    }, 3000);
  };

  return (
    <div className="card__container d-flex-col">
      <p className="card__title">{title}</p>
      <img className="card__img" src={img} alt={title} />
      <img
        className="card__img--mobile"
        src={mobileImg}
        loading="lazy"
        alt={title}
        onClick={handleClass}
      />
      <p
        className={`card__description ${descriptionClass}`}
        onClick={handleClass}
      >
        {description}
      </p>
    </div>
  );
};

export default Card;
