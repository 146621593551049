import CarouselSmall from "components/Carousel/CarouselSmall";
import giorni from "assets/giorni.svg";
import mobile_tutti from "assets/mobile_tutti.svg";
import useGetContentfulEntry from "hooks/useGetContentfulEntry";
import { CONTENTFUL_SOCIAL_SECTION_ID } from "constants/contentful";

const Social = () => {
  const { entry: social, loading } =
    useGetContentfulEntry(CONTENTFUL_SOCIAL_SECTION_ID) || {};

  if (loading) {
    return <div>loading...</div>;
  }

  const { images, link } = social;

  return (
    <div className="section social d-flex-col">
      <div className="header-placeholder"></div>
      <div className="social__container d-flex-col">
        <div className="social__title d-flex desktop">
          <p className="social__tutti">Tutti &nbsp;i</p>
          <img src={giorni} className="social__giorni" alt="giorni" />
        </div>
        <img
          className="social__title mobile"
          src={mobile_tutti}
          alt="tutti i giorni"
        />
        <div className="social__posts d-flex desktop">
          {images.map((image) => (
            <img
              key={image.sys.id}
              className="social__post"
              src={image.fields.file.url}
              alt={image.fields.title}
            />
          ))}
        </div>
        <div className="social__carousel mobile">
          <CarouselSmall images={images} />
        </div>
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="social__btn d-flex"
        >
          <span>Seguinos</span>
        </a>
      </div>
    </div>
  );
};

export default Social;
