import { useState, useEffect, useCallback } from "react";
import { useWindowSize } from "hooks/useWindowSize";
import { handleRem } from "helpers/handleRem";
import pd from "assets/pd.svg";
import useGetContentfulEntry from "hooks/useGetContentfulEntry";

const FixFooter = ({ fixed, scroll }) => {
  const [responsive, setResponsive] = useState({ footerHeight: 0 });

  const { height, width } = useWindowSize();

  const { entry: footer, loading } =
    useGetContentfulEntry("6fzOSIfE2RVSBjhmYg0fIg") || {};

  const handleResponsive = useCallback(() => {
    let rem = handleRem(width);
    if (height >= 700) {
      setResponsive({ footerHeight: (height - 5.6 * rem) * 5 });
    }
    if (width >= 600 && height < 700) {
      // pageHeight - footer

      // pageHeight
      // height - 5.6 * rem + (700 - 5.6 * rem) * 5 + rem * 5.6 - height
      setResponsive({
        footerHeight:
          height - 5.6 * rem + (700 - 5.6 * rem) * 5 + rem * 5.6 - height,
      });
    }
  }, [height, width]);

  useEffect(() => {
    handleResponsive();
  }, [handleResponsive]);

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <footer
      className={`fix d-flex ${fixed ? "fix--fixed" : ""} ${
        scroll >= responsive.footerHeight && fixed ? "hidden" : ""
      }`}
    >
      {footer && (
        <div className="fix__container d-flex">
          <p className="fix__address">{footer.address}</p>
          <img className="fix__img" src={pd} alt="pane & dolci" />
          <p className="fix__phone">{footer.phone}</p>
        </div>
      )}
    </footer>
  );
};

export default FixFooter;
