import Map from "components/Map/Map";
import useGetContentfulEntry from "hooks/useGetContentfulEntry";
import { CONTENTFUL_CONTACT_SECTION_ID } from "constants/contentful";

const Contact = () => {
  const { entry: contact, loading } =
    useGetContentfulEntry(CONTENTFUL_CONTACT_SECTION_ID) || {};

  if (loading) {
    return <div>loading...</div>;
  }

  const {
    address,
    btn1Description,
    btn2Description,
    leftBtnPhone,
    phone,
    rightBtnPhone,
    schedule1,
    schedule2,
    title,
    latitude,
    longitude,
  } = contact;

  const handlePhone = () => {
    if (rightBtnPhone.startsWith("549")) {
      return "0" + rightBtnPhone.slice(3);
    }
    return rightBtnPhone;
  };

  const displayedPhone = handlePhone();

  return (
    <div className="contact d-flex" id="contact">
      <div className="contact__container d-flex">
        <p className="contact__title--mobile mobile">Vení a conocernos</p>
        <div className="contact__map">
          <Map coordinates={[latitude, longitude]} />
        </div>
        <div className="contact__info d-flex-col">
          <div className="contact__content">
            <h1 className="contact__title desktop">{title}</h1>
            <p className="contact__data">{address}</p>
            <p className="contact__data">{phone}</p>
            <p className="contact__data">{schedule1}</p>
            <p className="contact__data">{schedule2}</p>
          </div>

          <div className="contact__actions d-flex">
            <div className="contact__action d-flex-col">
              <p className="contact__action-title">{btn1Description}</p>
              <a
                className="contact__btn d-flex"
                href={`https://wa.me/${leftBtnPhone}?text=`}
                target="_blank"
                rel="noreferrer"
              >
                <span>Pedidos</span>
              </a>
            </div>
            <div className="contact__action d-flex-col">
              <p className="contact__action-title">{btn2Description}</p>
              <a className="contact__btn d-flex" href={`tel:+${rightBtnPhone}`}>
                <span>{displayedPhone}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
