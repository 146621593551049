import { useState, useEffect } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Home from "pages/Home";
import About from "pages/About";
import Menu from "pages/Menu";
import Contact from "pages/Contact";
import Social from "pages/Social";
import Newsletter from "pages/Newsletter";
import FixFooter from "components/FixFooter";

const App = () => {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => setScroll(window.scrollY));
  }, []);

  return (
    <div className="app__container d-flex-col">
      <Header scroll={scroll} />
      <Home />
      <About />
      <Menu />
      <Contact />
      <Social />
      <Newsletter />
      <FixFooter fixed={true} scroll={scroll} />
      <Footer />
    </div>
  );
};

export default App;
